import { create } from "zustand"

interface AuthFormState {
  email: string
  phoneNumber: string
  // password: string
  name: string
  username: string
  rememberMe: boolean
  isLoggedIn: boolean
  // isLegalAge: boolean
  // setIsLegalAge: (isLegalAge: boolean) => void
  setRememberMe: (rememberMe: boolean) => void
  step: number
  resetStep: () => void
  setIsLoggedIn: () => void
  mode: "email" | "phone"
  setMode: (mode: "email" | "phone") => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  handlePrevStep: () => void
  handleNextStep: () => void
  setEmail: (email: string) => void
  setPhoneNumber: (phone: string) => void
  // setPassword: (password: string) => void
  setName: (name: string) => void
  setUsername: (username: string) => void
}

const useAuthFormStore = create<AuthFormState>((set) => ({
  email: "",
  phoneNumber: "",
  // password: "",
  name: "",
  username: "",
  // isLegalAge: true,
  step: 1,
  isLoggedIn: false,
  resetStep: () => set({ step: 1 }),
  rememberMe: true,
  mode: "email",
  isLoading: false,
  // setIsLegalAge: (isLegalAge) => set({ isLegalAge }),
  setIsLoggedIn: () => set({ isLoggedIn: true }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setMode: (mode) => set({ mode }),
  setRememberMe: (rememberMe) => set({ rememberMe }),
  handlePrevStep: () => set(({ step }) => ({ step: Math.max(step - 1, 1) })),
  handleNextStep: () => set(({ step }) => ({ step: step + 1 })),
  setEmail: (email) => set({ email }),
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  // setPassword: (password) => set({ password }),
  setName: (name: string) => set({ name }),
  setUsername: (username: string) => set({ username }),
}))

export default useAuthFormStore
