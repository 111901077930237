import * as React from "react";
import { cn } from "@/lib/utils";
import { Input } from "./input";
import { Label } from "./label";
import { Button } from "./button";
import Image from "next/image";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  ...props
}, ref) => {
  return <Input spellCheck={false} placeholder=" " className={cn("peer", className, "h-12 min-w-80 rounded-[10px] border border-white/40 text-white  hover:border-white/40 hover:bg-[#161724] focus-within:hover:bg-[#161724] focus-visible:border-link focus-visible:ring-0 focus-visible:hover:bg-[#161724]")} ref={ref} {...props} />;
});
FloatingInput.displayName = "FloatingInput";
const FloatingLabel = React.forwardRef<React.ElementRef<typeof Label>, React.ComponentPropsWithoutRef<typeof Label>>(({
  className,
  ...props
}, ref) => {
  return <Label className={cn("peer-focus:secondary peer-focus:dark:secondary absolute start-2 top-1.5 z-10 mx-1 origin-[0] -translate-y-4 scale-75 transform  px-2 text-sm duration-300 bg-[#161724] peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:font-normal peer-placeholder-shown:text-white/40 peer-hover:bg-[#161724] peer-focus:left-[1px] peer-focus:top-1.5 peer-focus:-translate-y-4 peer-focus:scale-75  peer-focus:px-2 peer-focus:text-link dark:bg-background rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4", className)} ref={ref} {...props} />;
});
FloatingLabel.displayName = "FloatingLabel";
type FloatingLabelInputProps = InputProps & {
  label?: string;
  labelClassName?: string;
  isInvalid?: boolean;
};
const FloatingLabelInput = React.forwardRef<React.ElementRef<typeof FloatingInput>, React.PropsWithoutRef<FloatingLabelInputProps>>(({
  id,
  label,
  type,
  isInvalid,
  labelClassName,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const inputType = type === "password" && showPassword ? "text" : type;
  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  return <div className="relative">
      <FloatingInput type={inputType} ref={ref} id={id} {...props} />
      <FloatingLabel htmlFor={id} className={labelClassName}>
        {label}
      </FloatingLabel>
      <div className="absolute right-3 top-1/2 flex -translate-y-1/2 items-center gap-2">
        {type === "password" && props?.value ? <Button type="button" variant="link" onClick={togglePasswordVisibility} className="p-0">
            <Image src={`${showPassword ? "/icons/sign-up/hide-password.svg" : "/icons/sign-up/show-password.svg"}`} width={24} height={24} alt="toggle password" />
          </Button> : null}
        {isInvalid ? <Image src="/icons/sign-up/warning.svg" width={24} height={24} alt="warning" /> : null}
      </div>
    </div>;
});
FloatingLabelInput.displayName = "FloatingLabelInput";
export { FloatingInput, FloatingLabel, FloatingLabelInput };