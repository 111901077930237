"use client";

import React from "react";
import { Button } from "../ui/button";
import Image from "next/image";
import { createClient } from "@/lib/supabase/client";
import { getURL } from "@/utils";
import { useApolloClient } from "@apollo/client";
const LoginWithGoogleButton = () => {
  const apolloClient = useApolloClient();
  async function signInWithGoogle() {
    const supabase = createClient();
    const {
      error
    } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: getURL() + "auth/callback"
      }
    });
    await apolloClient.resetStore();
    if (error) {
      return {
        error_code: error.code
      };
    }
  }
  return <Button onClick={signInWithGoogle} type="button" className="h-14 rounded-[8px] sm:rounded-[100px] sm:h-12 sm:py-5 hover:border-[#D9E0EC] bg-white/[4%] sm:active:border-link py-4 font-medium leading-7 shadow-none border-[#D9E0EC] hover:bg-white/[8%] focus:border-white focus:bg-white[4%] active:bg-white/[2%] disabled:bg-[#454a60]" data-sentry-element="Button" data-sentry-component="LoginWithGoogleButton" data-sentry-source-file="LoginWithGoogleButton.tsx">
      <span className="flex gap-4">
        <Image src="/icons/sign-up/google.svg" alt="google" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="LoginWithGoogleButton.tsx" />
        <span className="text-white text-base font-medium">
          Continue with Google
        </span>
      </span>
    </Button>;
};
export default LoginWithGoogleButton;